/* eslint-disable */
import HttpService from '../HttpService';
export default class FinancialGroupService {
    constructor() {
      this._httpService = new HttpService('/contract/financial_group/permiteds');
    }

    async FindAll(){
      return await this._httpService.get('');
    }

    async FindById(id) {
      return await this._httpService.get(`${id}`);
    }

    async FindAllWithFilters(queryString) {
      return await this._httpService.get(`${queryString}`);
    }
 };
