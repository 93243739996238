import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import RuleStore from '@/store/modules/rule/rule-module';
import MovementRuleStore from '@/store/modules/movement-rule/movement-rule-module';
import { MovementRuleModel, Rule, MovimentationTypeEnum } from '@/store/modules/movement-rule/movement-rule-types';
import Footer from '@/components/Footer/Footer.vue';
import router from '@/router';
import SimpleVueValidation from 'simple-vue-validator';
import RuleService from '@/services-http/sdi/RuleService';
import FinancialGroupService from '@/services-http/contract/FinancialGroupService';
import ContractService from '@/services-http/contract/ContractService';
import MovementRulesService from '@/services-http/sdi/MovementRulesService';
import CarrierService from '@/services-http/contract/CarrierService';
import lodash from 'lodash';

const { Validator } = SimpleVueValidation;

@Component({
  components: { Footer },
  validators: { 'movementRule.contract.id': (value: string) => Validator.value(value).required('Contrato é um campo obrigatório') },
})
export default class MovementRuleNew extends Vue {
  @Prop()
  id!: number;

  @Prop()
  edit!: boolean;

  @Prop()
  duplicate!: boolean;

  item = {};

  movementRule = new MovementRuleModel();

  movementRuleAux = new MovementRuleModel();

  ruleService = new RuleService();

  financialGroupService = new FinancialGroupService();

  contractService = new ContractService();

  movementRulesService = new MovementRulesService();

  carrierService = new CarrierService()

  insuranceCarriers: any = [];

  financialGroups = [];

  contracts = [];

  subContracts = [];

  selected: any[] = [];

  selectAll = false;

  disabledDataTable = true;

  loadingSubContracts = false;

  loadingContracts = false;

  loadingInsuranceCarrier = false;

  loadingFinancialGroups = false;

  loadingSave: any = false;

  ruleTableData: any[] = [];

  selectedRules: Rule[] = [];

  filteredRules = [];

  search = null;

  isInsuranceCarrier: any = false;

  isFinancialGroup: any = false;

  stepper = 1;

  headers = [
    {
      text: 'Regra',
      value: 'ruleName',
      align: 'left',
      width: '32%',
    },
    {
      text: 'Valor Padrão',
      value: 'defaultValue',
      align: 'left',
      width: '32%',
    },
    {
      text: 'Valor Configurado',
      value: 'configValue',
      align: 'left',
      width: '32%',
    },
    {
      text: ' ',
      align: 'right',
      value: 'data-table-expand',
      width: '4%',
    },
  ];

  headersMovementTypes = [
    {
      text: 'Tipo de Movimentação',
      value: 'description',
      align: 'left',
      width: '64%',
    },
    {
      text: 'Ação da Regra',
      value: 'ruleExceptionAction',
      align: 'left',
      width: '36%',
    },
  ];

  itIsInsuranceCarrier: any = true;

  itIsFinancialGroup: any = true;

  loadingExpandendTable = false;

  expanded = [];

  overlay = false;

  selectedContractNumber = ''

  selectedSubContractNumber = ''

  isEdit: any = false;

  isDuplicate: any = false;

  movimentationTypes = Object.values(MovimentationTypeEnum);

  movimentationTypesButtons: any =
    {
      RESTRICTIVE: {
        icon: 'fa-exclamation',
        color: '#e63946',
        iconColor: '#fff',
        hover: 'Restritiva',
      },
      INACTIVE: {
        icon: 'fa-thumbs-down',
        color: '#f4f4f4',
        iconColor: '#28253f',
        hover: 'Inativa',
      },

    }

  snackbar: any = {
    show: false,
    type: '',
    text: '',
  };

  async mounted() {
    this.overlay = true;
    this.loadInsuranceCarriers();
    await this.loadAllFinancialGroups();
    this.verifyRouter();
    await MovementRuleStore.fetchRuleList();
    await RuleStore.getRuleTags();
    this.verifyShowFields(this.$route.query);

    this.ruleTableData = this.ruleList;
    this.isEdit = JSON.parse(sessionStorage.typeRule).isEdit;
    this.isDuplicate = JSON.parse(sessionStorage.typeRule).isDuplicate;
    if (this.isEdit || this.isDuplicate) {
      await this.loadEditAndCopyMovementRules();
      this.verifyEditForType();

      if (!this.movementRule.status) {
        this.movementRule.status = false;
      }

      let rule: any;
      for (rule in this.movementRule.rules) {
        const ruleToCompare = this.movementRule.rules![rule];

        for (const index in this.ruleList) {
          if (this.ruleList[index].id === ruleToCompare.ruleId) {
            this.selected.push(this.ruleList[index].id);
          }
        }
      }
    } else {
      if (this.$route.query.insuranceCarrierId) {
        this.movementRule.insuranceCarrier.id = Number(this.$route.query.insuranceCarrierId);
      }
      if (this.$route.query.financialGroupId) {
        this.movementRule.financialGroup.id = Number(this.$route.query.financialGroupId);
        await this.loadContracstByFinancialGroupId(this.movementRule.financialGroup.id);
      }
    }
    this.overlay = false;
  }

  async loadInsuranceCarriers() {
    this.loadingInsuranceCarrier = true;
    this.carrierService.FindAll().then((response) => {
      if (response && response.data) {
        this.insuranceCarriers = response.data;
        this.loadingInsuranceCarrier = false;
      }
    }).catch((error) => {
      this.loadingInsuranceCarrier = false;
    });
  }

  async loadAllFinancialGroups() {
    this.loadingFinancialGroups = true;
    await this.financialGroupService.FindAll().then((response) => {
      if (response && response.data) {
        this.financialGroups = response.data;
        if (this.financialGroups.length) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          this.financialGroups = this.financialGroups.map((item: any) => ({
            ...item,
            text: item.name ? item.name : item.id,
          }));
        }
      }
    })
      .finally(() => {
        this.loadingFinancialGroups = false;
      });
  }

  async loadContracstByFinancialGroupId(financialGroupId: number) {
    this.contracts = [];
    this.subContracts = [];
    if (financialGroupId) {
      this.loadingContracts = true;
      const query = `financialGroupIds=${financialGroupId}`;
      await this.contractService.FindAllByFilters(query).then((response) => {
        if (response && response.data && response.data.length > 0) {
          const subContractsFalse = response.data.filter((contracts: any) => contracts.subcontract === false);
          this.contracts = subContractsFalse;
          if (this.contracts.length) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.contracts = this.contracts.map((item: any) => ({
              ...item,
              text: (`${(item.benefit_id ? item.benefit_id : '-')} - ${(item.carrier_id && item.carrier_id.xipp_commercial_name ? item.carrier_id.xipp_commercial_name : '-')} - ${item.policy ? item.policy : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
            }));
          }
        }
      }).finally(() => {
        this.loadingContracts = false;
      });
    }
  }

  async loadSubContractsByContractId(contractId: number) {
    this.loadingSubContracts = true;
    this.subContracts = [];
    if (contractId) {
      await this.contractService.FindByParentId(contractId).then((response) => {
        if (response && response.data && response.data.length > 0) {
          const subContractsTrue = response.data.filter((contracts: any) => contracts.subcontract === true);
          this.subContracts = subContractsTrue;
          if (this.subContracts.length) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.subContracts = this.subContracts.map((item: any) => ({
              ...item,
              text: (`${item.subcontract_number ? item.subcontract_number : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
            }));
            this.subContracts.sort((a: any, b: any) => a.subcontract_number.localeCompare(b.subcontract_number, { numeric: true }));
          }
        }
      }).finally(() => {
        this.loadingSubContracts = false;
      });
    }
  }

  get ruleList() {
    return MovementRuleStore.ruleList || [];
  }

  get ruleTags() {
    return RuleStore.ruleTags;
  }

  isMovementRuleValid() {
    if (this.movementRule.insuranceCarrier && this.movementRule.insuranceCarrier.id) {
      return true;
    }
    if (this.movementRule.financialGroup && this.movementRule.financialGroup.id && this.movementRule.contract.id) {
      return true;
    }
    return false;
  }

  verifyRouter() {
    if (this.$route.query.insuranceCarrierId) {
      this.isInsuranceCarrier = true;
    } else {
      this.isInsuranceCarrier = false;
    }
    if (this.$route.query.financialGroupId) {
      this.isFinancialGroup = true;
    } else {
      this.isFinancialGroup = false;
    }
  }

  getError(fieldName: string) {
    return this.validation.isTouched(fieldName) ? this.validation.firstError(fieldName) : '';
  }

  validateRules() {
    return this.selected.length > 0;
  }

  changeMovimentationType(column: any, currentValue: any, fieldName: string) {
    // eslint-disable-next-line no-param-reassign
    column[fieldName] = currentValue === MovimentationTypeEnum.INACTIVE ? MovimentationTypeEnum.RESTRICTIVE : MovimentationTypeEnum.INACTIVE;
  }

  selectAllRules() {
    if (this.selectAll) {
      for (const index in this.ruleTableData) {
        this.selected.push(this.ruleTableData[index].id);
      }
    } else {
      this.selected = [];
    }
  }

  validateFields() {
    if (!this.movementRule.contract.id) {
      Vue.toasted.global.customError('Selecione um contrato');
      return false;
    }

    if (!this.movementRule.subContract.id) {
      Vue.toasted.global.customError('Selecione um sub-contrato');
      return false;
    }

    if (this.selected.length === 0) {
      Vue.toasted.global.customError('Selecione pelo menos uma regra');
      return false;
    }

    return true;
  }

  saveMovementRule() {
    if (this.stepper < 2) {
      this.stepper = 2;
      for (const index in this.ruleList) {
        let configValue: any;
        if (this.isEdit) {
          let ruleIndex: any;
          for (ruleIndex in this.movementRule.rules) {
            if (this.ruleList[index].id === this.movementRule.rules![ruleIndex].ruleId) {
              configValue = this.movementRule.rules![ruleIndex].configValue;
            }
          }
        }

        if (this.selected.includes(this.ruleList[index].id)) {
          const aux = {
            ruleName: this.ruleList[index].name,
            defaultValue: this.ruleList[index].defaultValue,
            configValue,
            ruleId: this.ruleList[index].id,
            movementTypes: this.ruleList[index].movementTypes,
          };
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          this.selectedRules.push(aux);
        }
      }
      this.transformCssStyleToRulesIfExists();
    } else {
      this.verifyCriticismTypeWhenNotClickExpanded();
      this.movementRule.rules = this.selectedRules;
      this.expanded = [];

      this.normalizeRulesByMovementTypes();
      const customMovementRule = this.transformToPayload();

      if (!this.isEdit) {
        this.save(customMovementRule);
      } else if (this.isDuplicate) {
        this.save(customMovementRule);
      } else {
        this.update(customMovementRule);
      }
    }
  }

  save(data: any) {
    if (this.selectedRules && this.selectedRules.length) {
      this.loadingSave = true;
      this.movementRulesService.Save(data).then((response) => {
        Vue.toasted.global.customSuccess('Regra de Contrato salva com sucesso.');
        this.redirectToContracRule(1500);
        this.loadingSave = false;
      }).catch((error) => {
        if (error.response.data.code === 409) {
          this.loadingSave = false;
        } else {
          this.sendError();
          this.loadingSave = false;
        }
      });
    } else {
      this.customizeSnackbarMessage('error', 'Informe pelo menos uma regra para prosseguir');
    }
  }

  update(data: any) {
    if (this.selectedRules && this.selectedRules.length) {
      this.loadingSave = true;
      this.movementRulesService.Update(data, data.id)
        .then((response) => {
          this.loadingSave = false;
          Vue.toasted.global.customSuccess('Regra de Contrato atualizada com sucesso.');
        }).catch(() => {
          this.sendError();
          this.loadingSave = false;
        }).finally(() => {
          this.redirectToContracRule(1500);
          this.loadingSave = false;
        });
    } else {
      this.customizeSnackbarMessage('error', 'Informe pelo menos uma regra para prosseguir');
    }
  }

  sendError() {
    Vue.toasted.global.customError('Ocorreu um erro ao processar sua requisição. '
      + 'Tente novamente mais tarde.');
  }

  redirectToContracRule(timeMiliseconds: number) {
    setTimeout(() => {
      if (this.$route.query.insuranceCarrierId || this.$route.query.financialGroupId) {
        this.$router.push({ name: 'MovementRule', query: this.$route.query });
      } else {
        this.$router.push({ name: 'MovementRule' });
      }
    }, timeMiliseconds);
  }

  transformToPayload() {
    if (this.movementRule && this.movementRule.rules && this.movementRule.rules.length) {
      const movementRule = JSON.parse(JSON.stringify(this.movementRule));
      const customMovementRule = {
        id: movementRule.id ? movementRule.id : null,
        insuranceCarrierId: movementRule.insuranceCarrier && movementRule.insuranceCarrier.id ? movementRule.insuranceCarrier.id : null,
        financialGroupId: movementRule.financialGroup && movementRule.financialGroup.id ? movementRule.financialGroup.id : null,
        contractId: movementRule.contract && movementRule.contract.id ? movementRule.contract.id : null,
        subContractId: movementRule.subContract && movementRule.subContract.id ? movementRule.subContract.id : null,
        status: movementRule.status ? movementRule.status : false,
        rules: [],
      };

      const customRules: any[] = [];
      movementRule.rules.forEach((rule: any) => {
        const customRule = {
          configValue: rule.configValue ? rule.configValue : null,
          defaultValue: rule.defaultValue ? rule.defaultValue : null,
          ruleId: rule.ruleId ? rule.ruleId : null,
          name: rule.ruleName ? rule.ruleName : null,
          movementSpecificRuleMovementTypes: [],
        };

        const movementSpecificRuleMovementTypes: any[] = [];
        if (rule.movementTypes) {
          rule.movementTypes.forEach((movementType: any) => {
            const customRUleMovementType = {
              criticismType: movementType.criticimsType,
              movementType: {
                id: movementType.id ? movementType.id : 0,
                code: movementType.code ? movementType.code : null,
                description: movementType.description ? movementType.description : null,
                name: movementType.name ? movementType.name : null,
              },
            };

            movementSpecificRuleMovementTypes.push(customRUleMovementType);
          });
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        customRule.movementSpecificRuleMovementTypes = movementSpecificRuleMovementTypes;
        customRules.push(customRule);
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      customMovementRule.rules = customRules;
      return customMovementRule;
    }
    return this.movementRule;
  }

  transformCssStyleToRulesIfExists() {
    if (this.isEdit) {
      // eslint-disable-next-line max-len
      if (this.movementRule.rules?.length) {
        this.movementRule.rules.forEach((rule: any) => {
          const formattedMovementTypes: any [] = [];
          if (rule.movementSpecificRuleMovementTypes) {
            const ruleMovementTupes = rule.movementSpecificRuleMovementTypes;
            ruleMovementTupes.forEach((item: any) => {
              const newMovementTypeFormat = {
                criticimsType: item.criticismType,
                ...item.movementType,
              };
              formattedMovementTypes.push(newMovementTypeFormat);
            });
          }

          const ruleFinded = this.selectedRules.find((item) => item.ruleId === rule.ruleId);
          if (ruleFinded) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const indexOf = this.selectedRules.indexOf(ruleFinded);
            this.selectedRules[indexOf].movementTypes = formattedMovementTypes;
          }
        });
      }
      this.movementRuleAux.rules = this.movementRule.rules;
      this.movementRule.rules = [];
    }
  }

  verifyCriticismTypeWhenNotClickExpanded() {
    if (this.selectedRules.length) {
      this.selectedRules.forEach((item) => {
        if (item.movementTypes && item.movementTypes.length) {
          this.onClickExpanded(item);
        }
      });
    }
  }

  removeFromList(item: any) {
    this.selected.splice(item, 1);
    this.selectedRules.splice(item, 1);
  }

  async filterRules() {
    await MovementRuleStore.fetchRuleListByTag(this.filteredRules);

    this.ruleTableData = MovementRuleStore.ruleListByTags || [];
  }

  cancel() {
    this.$router.push({ name: 'HomeAllMenus' });
  }

  back() {
    this.expanded = [];
    if (this.isEdit) {
      this.movementRule.rules = this.movementRuleAux.rules;
      this.movementRuleAux.rules = [];
    }

    if (this.stepper > 1) {
      this.stepper = 1;
      this.selectedRules = [];
    } else {
      this.$router.push({ name: 'MovementRule', query: this.$route.query });
    }
  }

  onClickExpanded(item: any) {
    if (item.movementTypes && item.movementTypes.length) {
      // eslint-disable-next-line no-param-reassign
      item.movementTypes = item.movementTypes.map((movementType: any) => ({
        ...movementType,
        criticimsType: movementType.criticimsType ? movementType.criticimsType : this.loadCriticismType(movementType.criticimsType),
        color: movementType.color ? movementType.color : this.movimentationTypesButtons[this.loadCriticismType(movementType.criticimsType)].color,
        iconColor: movementType.iconColor ? movementType.iconColor : this.movimentationTypesButtons[this.loadCriticismType(movementType.criticimsType)].iconColor,
        icon: movementType.icon ? movementType.icon : this.movimentationTypesButtons[this.loadCriticismType(movementType.criticimsType)].icon,
        hover: movementType.hover ? movementType.hover : this.movimentationTypesButtons[this.loadCriticismType(movementType.criticimsType)].hover,
      }));
    }
  }

  loadCriticismType(critiscmType: string) {
    switch (critiscmType) {
      case MovimentationTypeEnum.RESTRICTIVE:
        return MovimentationTypeEnum.RESTRICTIVE;
      default:
        return MovimentationTypeEnum.INACTIVE;
    }
  }

  onClickRuleExceptionAction(item: any) {
    const targetCriticimsType = item.criticimsType === MovimentationTypeEnum.INACTIVE ? MovimentationTypeEnum.RESTRICTIVE : MovimentationTypeEnum.INACTIVE;
    const targetButton = this.movimentationTypesButtons[targetCriticimsType];

    // eslint-disable-next-line no-param-reassign
    item.criticimsType = targetCriticimsType;
    // eslint-disable-next-line no-param-reassign
    item.color = targetButton.color;
    // eslint-disable-next-line no-param-reassign
    item.iconColor = targetButton.iconColor;
    // eslint-disable-next-line no-param-reassign
    item.icon = targetButton.icon;
    // eslint-disable-next-line no-param-reassign
    item.hover = targetButton.hover;
  }

  normalizeRulesByMovementTypes() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (this.movementRule.rules.length) {
      // eslint-disable-next-line no-unused-expressions
      this.movementRule.rules?.forEach((rule) => {
        if (rule.movementTypes && rule.movementTypes.length) {
          rule.movementTypes.forEach((movementType: any) => {
            // eslint-disable-next-line no-param-reassign
            delete movementType.color;
            // eslint-disable-next-line no-param-reassign
            delete movementType.iconColor;
            // eslint-disable-next-line no-param-reassign
            delete movementType.icon;
            // eslint-disable-next-line no-param-reassign
            delete movementType.hover;
          });
        }
      });
    }
  }

  async loadEditAndCopyMovementRules() {
    const obj = JSON.parse(sessionStorage.typeRule);
    await this.movementRulesService.FindById(obj.movementRule.id).then((response) => {
      this.movementRule = response.data;
      this.movementRule.financialGroup = this.movementRule.financialGroup === null ? this.movementRule.financialGroup = { id: null } : this.movementRule.financialGroup;
      this.movementRule.insuranceCarrier = this.movementRule.insuranceCarrier === null ? this.movementRule.insuranceCarrier = { id: null } : this.movementRule.insuranceCarrier;
      this.movementRule.contract = this.movementRule.contract === null ? this.movementRule.contract = { id: null } : this.movementRule.contract;
      this.movementRule.subContract = this.movementRule.subContract === null ? this.movementRule.subContract = { id: null } : this.movementRule.subContract;
    });
    if (this.movementRule.financialGroup.id) {
      await this.loadContracstByFinancialGroupId(this.movementRule.financialGroup.id);
      await this.loadSubContractsByContractId(this.movementRule.contract.id);
    }
  }

  verifyEditForType() {
    if (this.isEdit) {
      if (this.movementRule.financialGroup && this.movementRule.financialGroup.id !== null) {
        this.verifyShowFields({ financialGroupId: this.movementRule.financialGroup.id });
      } else if (this.movementRule.insuranceCarrier && this.movementRule.insuranceCarrier.id !== null) {
        this.verifyShowFields({ insuranceCarrierId: this.movementRule.insuranceCarrier.id });
      }
    }
  }

  verifyShowFields(obj: any) {
    if (!lodash.isEmpty(obj)) {
      if (obj.insuranceCarrierId) {
        this.itIsInsuranceCarrier = true;
        this.itIsFinancialGroup = false;
      } else if (obj.financialGroupId) {
        this.itIsFinancialGroup = true;
        this.itIsInsuranceCarrier = false;
      }
    }
  }

  customizeSnackbarMessage(type: string, text: string) {
    this.snackbar.show = true;
    this.snackbar.type = type;
    this.snackbar.text = text;
  }
}
