/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementRulesService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-rules');
  }

  async FindById(id) {
    return await this._httpService.get(`/${id}`);
  }

  async FindAllByFilter(query) {
    return await this._httpService.get('', query);
  }

  async Save(data) {
    return await this._httpService.post('', data);
  }

  async Update(data, id) {
    return await this._httpService.put(`/${id}`, data);
  }

  async Delete(id) {
    return await this._httpService.delete(`/${id}`);
  }
}
