import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  @PropSync('disabledSave', { default: true })
  disabled!: boolean;

  @PropSync('disabledBack', { default: true })
  showBackButton!: boolean;

  @PropSync('disabledCancel', { default: true })
  showCancelButton!: boolean;

  @PropSync('loadingSave', { default: false })
  loading!: boolean;

  @Prop({ default: 'Salvar' })
  saveButtonLabel!: boolean;

  @Prop({ default: 'Voltar' })
  backButtonLabel!: boolean;

  @Prop({ default: 'Cancelar' })
  cancelButtonLabel!: boolean;
}
