/* eslint-disable */
import HttpService from '../HttpService'

export default class RuleService {
  constructor() {
    this._httpService = new HttpService('/sdi/rule');
  }

  async FindAll() {
    return await this._httpService.get('');
  }

  async FindById(id) {
    return await this._httpService.get('',{ id });
  }
}
